<template>
  <b-view v-if="isFull" />
  <div v-else>
    <b-app-bar @showLeftBar="showLeftBar" />
    <v-app-bar
      v-if="!isFull"
      clipped-right
      app
      dense
      flat
      tag="div"
      style="background-color: #f7f7f7; top: 64px"
    >
      <rzBreadcrumbs></rzBreadcrumbs>
    </v-app-bar>
    <b-drawer ref="bDrawer" />
    <b-view />
    <v-footer app inset><v-spacer />  </v-footer>
  </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
  components: {
    BAppBar: () => import('@/components/layout/BAppBar'),
    BDrawer: () => import('@/components/layout/BDrawer'),
    BView: () => import('@/components/layout/BView'),
    rzBreadcrumbs: () => import('@/components/layout/rz-breadcrumbs'),
  },
  computed: {
    ...mapState({
      isFull: (state) => state.screenStore.isFull,
    }),
  },
  methods: {
    showLeftBar(e) {
      this.$refs.bDrawer.showLeftBarFlag = e;
    },
  },
};
</script>
<style lang="scss" scoped></style>
